/**
 * A generic context provider for managing an array of Alerts to display.
 *
 * This provider uses generic types to avoid making assumptions about your alerts state.
 * The only assumption is that your alerts state can be modeled as an array of records.
 *
 * The context-reducer pattern used here is based on:
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively
 * See also: http://kcd.im/optimize-context
 */
import React from 'react'
import { randHex } from '../utils/functions'

// The state thate represents any single alert must extend AlertPropsBase
interface AlertPropsBase extends Record<string, any> {
  id?: string
}

// We use a Redux-like context-reducer pattern based on dispatching actions
type ActionBase = { type: string; payload?: AlertPropsBase }

// The interface for an "enqueue" alert action
interface ActionEnqueue<AlertProps extends AlertPropsBase> extends ActionBase {
  type: 'enqueue'
  payload: AlertProps
}

// The interface for a "dequeue" alert action
interface ActionDequeue extends ActionBase {
  type: 'dequeue'
  payload?: undefined
}

interface ActionDelete extends ActionBase {
  type: 'delete'
  payload: { id: string }
}

// A valid action is the union of all defined Action* types
type Action<AlertProps extends AlertPropsBase> = ActionEnqueue<AlertProps> | ActionDequeue | ActionDelete

// Export types for alert state, context, and action dispatcher
export type State<AlertProps extends AlertPropsBase> = Array<AlertProps>
export type Dispatch<AlertProps extends AlertPropsBase> = (action: Action<AlertProps>) => void
export interface Context<AlertProps extends AlertPropsBase> {
  state: AlertProps[]
  dispatch: Dispatch<AlertProps>
}

let _getContextMemo: React.Context<Context<any> | undefined> | undefined
let _getReducerMemo: React.Reducer<State<any>, Action<any>> | undefined

/**
 * Return a memoized and typed React context.
 * This should not be exported outside this module.
 */
const getContext = <AlertProps extends AlertPropsBase>(): React.Context<Context<AlertProps> | undefined> => {
  if (!_getContextMemo) {
    _getContextMemo = React.createContext<Context<AlertProps> | undefined>(undefined)
  }
  return _getContextMemo
}

/**
 * Return a reducer that translates actions to state updates.
 */
const getReducer = <AlertProps extends AlertPropsBase>() => {
  if (!_getReducerMemo) {
    _getReducerMemo = (state: State<AlertProps>, action: Action<AlertProps>): State<AlertProps> => {
      switch (action.type) {
        case 'enqueue': {
          return [action.payload, ...state]
        }
        case 'dequeue': {
          try {
            state.pop()
          } catch {
            console.error('Received "dequeue" alert action but the alerts queue is empty!')
          }
          return [...state]
        }
        case 'delete': {
          const newState = state.filter((alertProps) => alertProps.id !== action.payload.id)
          if (newState.length === state.length) {
            console.warn(`Received "delete" alert action for id "${String(action.payload.id)}" that does not exist`)
          }
          return newState
        }
        default: {
          // @ts-ignore: TS is correct that this *should* be unreachable, but static types are not foolproof
          throw new Error(`Unhandled action type: ${action.type}`)
        }
      }
    }
  }
  return _getReducerMemo
}

// Props for the default-exported provider componenet
export interface AlertsProviderProps<AlertProps extends AlertPropsBase> {
  initialContext?: AlertProps[]
}

/**
 * A React context provider for managing generic alert notifications.
 */
const AlertsProvider = <AlertProps extends AlertPropsBase>({
  initialContext,
  children,
}: React.PropsWithChildren<AlertsProviderProps<AlertProps>>): JSX.Element => {
  const reducer = getReducer<AlertProps>()
  const Context = getContext<AlertProps>()
  const [state, dispatch] = React.useReducer(reducer, initialContext || [])
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
export default AlertsProvider

/**
 * React hook for retrieving the alerts context.
 * Useful for reading the current state and dispatching update actions.
 *
 * Usage:
 *     const {state, dispatch} = useAlerts<AlertProps>()
 */
export const useAlerts: <AlertProps extends AlertPropsBase>() => Context<AlertProps> = <
  AlertProps extends AlertPropsBase,
>() => {
  const context = React.useContext(getContext<AlertProps>())
  if (context === undefined) {
    throw new Error('useAlerts must be used within an AlertsProvider')
  }
  return context
}

/**
 * Utility method for returning the current state.
 * Must be called from inside a React function component.
 */
export const getAlerts = <AlertProps extends AlertPropsBase>(): State<AlertProps> => {
  const { state } = useAlerts<AlertProps>()
  return state
}

/**
 * Utility method for dispatching an "enqueue" action with a given payload.
 * Enqueuing an alert adds it to the back of the queue (so they can be removed in FIFO order)
 *
 * Usage:
 *     const {dispatch} = useAlerts<AlertProps>()
 *     const alertId = enqueueAlert(dispatch, alertProps)
 */
export const enqueueAlert = <AlertProps extends AlertPropsBase>(
  dispatch: Dispatch<AlertProps>,
  payload: AlertProps,
): string => {
  if (payload.id === undefined) {
    payload.id = randHex(16)
  }
  dispatch({ type: 'enqueue', payload })
  return payload.key
}

/**
 * Utility method for dispatching a "dequeue" action.
 * Dequeuing an alert removes the oldest alert from the front of the queue.
 *
 * Usage:
 *     const {dispatch} = useAlerts<AlertProps>()
 *     dequeueAlert(dispatch)
 */
export const dequeueAlert = <AlertProps extends AlertPropsBase>(dispatch: Dispatch<AlertProps>): void => {
  dispatch({ type: 'dequeue' })
}

/**
 * Utility method for dispatching a "delete" action.
 * Deleting an alert removes a specific alert by its ID.
 *
 * Usage:
 *     const {dispatch} = useAlerts<AlertProps>()
 *     const id = enqueueAlert(dispatch, alertProps)
 *     deleteAlert(dispatch, {id})
 */
export const deleteAlert = <AlertProps extends AlertPropsBase>(
  dispatch: Dispatch<AlertProps>,
  payload: { id: string },
): void => {
  dispatch({ type: 'delete', payload })
}
