import React from 'react'
import { Button } from '@material-ui/core'

import useStyles from './styles'
import Spinner from './assets/TimeoutSection.png'
import Circle from './assets/TimeoutCircle.png'

interface Props {
  time: number
  continueSession: () => void
  endSession: React.MouseEventHandler<HTMLButtonElement>
}

const CountDown = ({ time, endSession, continueSession }: Props): React.ReactElement => {
  const { modal, spinner, circle, counter, copy, endSessionButton, buttonGroup } = useStyles()

  return (
    <div className={modal} onClick={continueSession}>
      <div className={circle}>
        <img src={Spinner} alt="" className={spinner} draggable={false} />
        <img src={Circle} alt="" draggable={false} />
        <p className={counter}>{time}s</p>
      </div>
      <div className={copy}>
        <h1>Session Timeout</h1>
        <p>Your session is about to expire due to inactivity. Please select from the options below.</p>
        <div className={buttonGroup}>
          <Button onClick={endSession} variant="outlined" color="secondary" classes={{ root: endSessionButton }}>
            End Session
          </Button>
          <Button variant="contained" color="secondary" classes={{ root: endSessionButton }}>
            Continue Session
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CountDown
